<template>
    <div class="row">
        <div class="col-12" v-for="incident in incidents">
            <div class="card">
                <div class="card-header py-2">Incident</div>
                <div class="card-body">
                    <table class="table table-sm" style="font-size: 80%">
                        <tr>
                            <th colspan="1">Beschrijving</th>
                            <td colspan="4">{{ incident.briefDescription }}</td>
                        </tr>
                        <tr>
                            <th colspan="1">Datum</th>
                            <td colspan="4">{{ $moment(incident.creationDate).format('DD MMMM YYYY HH:mm:ss') }}</td>
                        </tr>
                        <tr>
                            <th colspan="1">Vestiging</th>
                            <td colspan="4">
                                <template v-if="incident.callerBranch">
                                    {{ incident.callerBranch.name }}
                                    <template v-if="incident.callerBranch.clientReferenceNumber">
                                        <i class="fa fa-link"></i>&nbsp;
                                        <span class="badge badge-primary" title="Exact koppeling">{{ incident.callerBranch.clientReferenceNumber }}</span>
                                    </template>
                                </template>
                            </td>
                        </tr>
                        <tr>
                            <th colspan="1">Code</th>
                            <td colspan="4">
                                <span class="badge badge-primary">{{ incident.number }}</span>
                                &nbsp;
                                <a :href="linkToTopdesk(incident.id)" target="_blank" title="Bekijk in TOPdesk"><i
                                        class="fa fa-external-link"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <th colspan="5"></th>
                        </tr>
                        <tr>
                            <th>Factureren?</th>
                            <th>Uur</th>
                            <th>Datum</th>
                            <th>Reden</th>
                            <th>Beschrijving</th>
                            <th>Behandelaar</th>
                        </tr>
                        <template v-if="incident.timeSpentFull && incident.timeSpentFull.length">
                            <tr v-for="timeSpent in incident.timeSpentFull">
                                <td><b-form-checkbox v-model="timeSpent.checked"></b-form-checkbox></td>
                                <td><span class="text-primary font-weight-bold">{{ timeSpent.timeSpent | minutesToTime }}</span>
                                </td>
                                <td>{{ $moment(timeSpent.entryDate).format('DD-MM-YYYY HH:mm') }}</td>
                                <td><template v-if="timeSpent.reason">{{ timeSpent.reason.name }}</template></td>
                                <td>{{ timeSpent.notes }}</td>
                                <td>{{ timeSpent.operator ? timeSpent.operator.name : '' }}</td>
                            </tr>
                        </template>
                        <tr v-else>
                            <td colspan="5" class="text-muted">[ Leeg ]</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import get from 'lodash.get';

    export default {
        props: ['incidents', 'apiConfigTopdesk'],
        methods: {
            /**
             * @param {String} id
             * @returns {string}
             */
            linkToTopdesk(id) {
                let endpoint = get(this.apiConfigTopdesk, 'config.api_endpoint');
                if(endpoint) {
                    id = id.replace(/-/g, '');
                    return endpoint + `/tas/secure/incident?action=show&unid=${id}`;
                }
            }
        }
    }
</script>
