<template>
    <div class="row">
        <div class="col-8">
            <div class="card">
                <div class="card-header"><i class="fa fa-file-text-o"></i> Factuur</div>
                <div class="card-body">
                    <form @submit.prevent="handle()">
                        <b-form-group label="Debiteur" v-if="invoicing_accounts.length">
                            <select2 required :options="invoicing_accounts"
                                     :config="{allowClear: true, placeholder: '[ Kies een optie ]'}"
                                     v-model="client_reference_number" style="min-width: 200px">
                            </select2>
                        </b-form-group>
                        <table class="table table-sm">
                            <tr>
                                <th>Omschrijving</th>
                                <th>Notities</th>
                                <th>Aantal uur <small class="text-muted">(invoer in minuten)</small></th>
                                <th>Tarief</th>
                                <th>Totaal</th>
                                <th></th>
                            </tr>
                            <tbody name="fade" is="transition-group">
                            <tr v-for="(line, index) in invoice_lines" :key="index">
                                <td>
                                    <b-form-input required size="sm" v-model="line.description"></b-form-input>
                                </td>
                                <td><textarea rows="1" class="form-control" v-model="line.note"></textarea></td>
                                <td>
                                    <b-input-group size="sm" style="max-width: 150px">
                                        <b-input-group-prepend>
                                            <b-input-group-text>
                                                <b class="text-primary">{{ line.time_spent | minutesToTime }}</b>
                                            </b-input-group-text>
                                        </b-input-group-prepend>
                                        <b-form-input required v-model="line.time_spent"></b-form-input>
                                    </b-input-group>
                                </td>
                                <td>
                                    <b-input-group size="sm" style="max-width: 150px">
                                        <b-input-group-prepend>
                                            <b-input-group-text>
                                                {{ line.hourly_rate | toCurrency }}
                                            </b-input-group-text>
                                        </b-input-group-prepend>
                                        <b-form-input required v-model="line.hourly_rate"
                                                      @keypress.native="onlyNumber"></b-form-input>
                                    </b-input-group>
                                </td>
                                <td>{{ line.hourly_rate * (line.time_spent / 60) | toCurrency }}</td>
                                <td class="text-right">
                                    <button class="btn btn-sm btn-outline-danger" type="button"
                                            @click="invoice_lines.splice(index, 1)"><i class="fa fa-trash"></i></button>
                                </td>
                            </tr>
                            </tbody>
                            <tr>
                                <th colspan="100">
                                    <button class="btn btn-sm btn-outline-primary w-100" type="button"
                                            @click="invoice_lines.push({})"><i class="fa fa-plus"></i> Nieuwe regel
                                    </button>
                                </th>
                            </tr>
                        </table>
                        <b-form-group>
                            <b-form-checkbox v-model="save_debtor_in_topdesk_branch">Debiteur opslaan in vestiging
                                TOPdesk
                            </b-form-checkbox>
                        </b-form-group>
                        <button v-if="!created_invoice" class="btn btn-primary" type="submit">Factuur aanmaken</button>
                        <button v-else class="btn btn-primary" disabled>Factuur aangemaakt!</button>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-4">
            <incidents-component v-if="!loading_incidents" :incidents="incidents"
                                 :apiConfigTopdesk="api_config_topdesk"/>
            <loading-button class="btn btn-outline-primary disabled" :loading="true" v-else></loading-button>
        </div>
    </div>
</template>

<script>
    import qs from 'qs';
    import get from 'lodash.get';
    import IncidentsComponent from '@/views/invoicing/Incidents';
    import MixinInvoiceLines from '@/views/invoicing/MixinInvoiceLines';
    import mixin from "../settings/mixin";

    export default {
        mounted() {
            this.ids = this.$route.query.ids;
            this.ids = Array.isArray(this.ids) ? this.ids : [this.ids];
            this.getApiConfigTopdesk()
                .then(this.getConfig)
                .then(this.getInvoicingAccounts)
                .then(this.getInvoicingApiConfig)
                .then(this.getIncidents)
                .then(() => {
                    let incident = this.firstLinkedIncident;
                    if (incident) {
                        if (this.apiConfigType === 'exact') {
                            // Exact needs the client code to be padded with spaces
                            this.client_reference_number = String(get(incident, 'callerBranchFull.clientReferenceNumber')).padStart(18, ' ');
                        } else {
                            console.log(incident);
                            this.client_reference_number = String(get(incident, 'callerBranchFull.clientReferenceNumber'));
                        }
                    }
                    this.loading = false;
                });
        },
        mixins: [MixinInvoiceLines],
        components: {
            IncidentsComponent
        },
        watch: {
            incidents: {
                handler(value) {
                    this.invoice_lines = this.$_createInvoiceLines(value);
                },
                deep:true
            }
        },
        data() {
            return {
                ids: [],
                incidents: [],
                invoice_lines: [],
                invoicing_api_config: {},
                api_config_topdesk: {},
                configs: [],
                invoicing_accounts: [],
                client_reference_number: undefined,
                loading: true,
                loading_incidents: true,
                created_invoice: false,
                save_debtor_in_topdesk_branch: true,
            }
        },
        computed: {
            /**
             * Find the first incident with a clientReferenceNumber.
             * @return {Object|undefined}
             */
            firstLinkedIncident() {
                for (let incident of this.incidents) {
                    let nr = get(incident, 'callerBranchFull.clientReferenceNumber');
                    if (nr) {
                        return incident;
                    }
                }
            },
            /**
             * Find the first branch from the incidents.
             * @return {Object|undefined}
             */
            firstBranch() {
                for (let incident of this.incidents) {
                    let branch = get(incident, 'callerBranchFull');
                    if (branch) {
                        return branch;
                    }
                }
            },
            apiConfigType() {
                return this.getConfigValue('invoicing_api');
            },
        },
        methods: {
            getConfig() {
                return this.$http.get('config').then((response) => {
                    this.configs = response.data;
                });
            },
            getConfigValue(name) {
                for (let config of this.configs) {
                    if (config.name === name) {
                        return config.value;
                    }
                }
            },
            /**
             * Get api_config for the chosen invoicing solution.
             */
            getInvoicingApiConfig() {
                return this.$http.get(`api-config/${this.apiConfigType}`).then((response) => {
                    this.invoicing_api_config = response.data;
                });
            },
            getApiConfigTopdesk() {
                return this.$http.get(`api-config/topdesk`).then((response) => {
                    this.api_config_topdesk = response.data;
                });
            },
            getIncidents() {
                let query = qs.stringify({
                    ids: this.ids,
                    'full-branch': true,
                    'full-timespent': true,
                    status: this.api_config_topdesk.config.filter_status || '',
                    operator_group : this.api_config_topdesk.config.operator_group || '',
                    branch: this.selected_branch,
                });

                return this.$http.get('topdesk/api/incidents?' + query).then((response) => {
                    this.incidents = response.data.map(function(incident) {
                        incident.timeSpentFull.map(function(time) {
                            time.checked = true;
                            return time;
                        });
                        return incident;
                    });

                    this.loading_incidents = false;
                });
            },
            getInvoicingAccounts() {
                switch (this.apiConfigType) {
                    case 'exact':
                        return this.$http.get('exact/api/accounts').then((response) => {
                            let accounts = response.data.filter((item) => {
                                return item.IsSales;
                            });

                            this.invoicing_accounts = accounts.map((item) => {
                                // Add keys for select2.
                                item.id = item.id || item.Code;
                                item.text = item.text || item.Name;
                                return item;
                            });
                        }).catch(() => {
                            // Continue promise chain.
                        });
                    case 'teamleader':
                        return this.$http.get('teamleader/companies').then((response) => {
                            let items = response.data;

                            this.invoicing_accounts = items.map((item) => {
                                // Add keys for select2.
                                item.text = item.name;
                                return item;
                            });
                        }).catch(() => {
                            // Continue promise chain.
                        });
                }
            },
            updateBranch(id, data) {
                return this.$http.put(`topdesk/api/branches/${id}`, data);
            },
            handle() {
                if (this.save_debtor_in_topdesk_branch) {
                    let branch = this.firstBranch;
                    if (branch) {
                        this.updateBranch(branch.id, {
                            clientReferenceNumber: this.client_reference_number
                        });
                    }
                }

                let config = get(this.api_config_topdesk, 'config');

                this.createInvoice()
                    .then(() => {
                        if (config.change_status) {
                            let data = {
                                'processingStatus': {
                                    'id': config.change_status
                                }
                            };

                            return Promise.all(this.ids.map((id) => {
                                return this.$http.put(`topdesk/api/incidents/${id}`, data);
                            }));
                        }
                    })
                    .then(() => {
                        if (config.archive_on_close) {
                            return Promise.all(this.incidents.map((incident) => {
                                return this.$http.put(`topdesk/api/incidents/${incident.id}/archive`)
                            }));
                        }
                    })
                    .then(() => {
                        this.created_invoice = true;
                        this.$toastr.s('Factuur aangemaakt!');
                    });
            },
            createInvoice() {
                let guid;
                if (this.apiConfigType === 'exact') {
                    for (let account of this.invoicing_accounts) {
                        if (account.Code === String(this.client_reference_number).padStart(18, ' ')) {
                            guid = account.ID;
                        }
                    }
                } else {
                    guid = this.client_reference_number;
                }
                return this.$http.post('invoice/create', {
                    account_id: guid,
                    invoice_lines: this.invoice_lines
                });
            }
        }
    }
</script>
