import get from 'lodash.get';

/**
 * Mixin containing functions for calculating and creating invoice lines.
 */
export default {
    methods: {
        /**
         * @param {Object[]} incidents
         * @return {Array}
         */
        $_createInvoiceLines(incidents) {
            let lines = [];
            incidents.forEach((incident) => {
                let note = '';
                let time = 0;
                if(incident.timeSpentFull) {
                    let first = true;
                    for(let timeSpent of incident.timeSpentFull) {
                        if(!timeSpent.checked) continue;

                        if(first) first = false;
                        else note += "\n";

                        let text = timeSpent.notes || '-';
                        note += this.$options.filters.minutesToTime(timeSpent.timeSpent) + '   ' + text;
                        time += timeSpent.timeSpent;
                    }
                }
                let line = {
                    description: incident.number + ' ' + incident.briefDescription,
                    note: note,
                    time_spent: this.$_calculateTimeSpentForIncident(incident, time),
                    hourly_rate: this.$_calculateHourlyRateForIncident(incident)
                };

                line.hourly_rate = typeof line.hourly_rate === 'string' ? line.hourly_rate.replace(',', '.') : line.hourly_rate;

                lines.push(line);
            });

            return lines;
        },
        /**
         * Custom code for Yellow Arrow, 'yellowgids' and 'yellowgids grenzeloos' are the subscriptions they have
         * for their customers. The return value is the amount that will be invoiced.
         * @param {Object} incident
         * @returns {number|*}
         */
        $_calculateHourlyRateForIncident(incident) {
            // "yellowarrow" custom code: return empty price for these incidents.
            let customValue = get(incident, 'callerBranch.extraA.name');
            if(customValue && customValue.toLowerCase() === 'yellowgids') {
                let callType = get(incident, 'callType.name');
                if(callType && callType.toLowerCase() !== 'wijziging') {
                    return 0;
                }
            }
            if(customValue && customValue.toLowerCase() === 'yellowgids grenzeloos') {
                return 0;
            }

            return get(incident, 'callerBranchFull.optionalFields1.number1') || get(this.invoicing_api_config, 'config.default_rate')
        },
        /**
         * @param {Object} incident
         * @param {int} time
         * @returns {number}
         */
        $_calculateTimeSpentForIncident(incident, time) {
            let minutes = parseInt(this.getConfigValue('invoicing_round_time_spent_minutes'), 10);
            let round = this.getConfigValue('invoicing_round_time_spent_type');
            let minimum = parseInt(this.getConfigValue('invoicing_round_time_spent_minimum'), 10);

            if(!minutes) {
                return time;
            }
            let mod = time % minutes;
            switch(round) {
                case 'up':
                    time = time - mod + (mod > 0 ? minutes : 0);
                    break;
                case 'down':
                    time = time - mod;
                    break;
                case 'default':
                default:
                    time = time - mod + (mod >= minutes/2 ? minutes : 0);
                    break;

            }
            return time < minimum ? minimum : time;
        },
    }
}
